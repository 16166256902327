import React, { useState, useRef } from "react";
import PropTypes, { node } from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Gallery from "../components/Gallery";

export const GalleryPageTemplate = ({
    title,
    galleries
}) => {
    const [selected, setSelected] = useState(0);
    const scrollRef = useRef();
    return (
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                            {title}
                        </h2>
                        <div className="columns">
                            {
                                galleries.map(({artist_name, artist_about, images}, index) => {
                                    return (
                                    <div className="column is-4">
                                        <div style={{
                                            background: `url(${images[0].image.childImageSharp.fluid.src})`,
                                            backgroundPosition: `center`,
                                            backgroundSize: `cover`,
                                            minHeight: 300,
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                background: "#4a4a4a",
                                                alignSelf: "flex-end",
                                                flexGrow: 1,
                                                opacity: 0.9,
                                                padding: 10,
                                                color: "white"
                                            }}>
                                                <h4 className="title is-size-2 has-text-weight-bold is-bold-light" style={{color: "white", marginBottom: "0.5em"}}>
                                                    {artist_name}
                                                </h4>
                                                <p>{artist_about}</p>
                                                <button className="button is-link" onClick={() => {
                                                    setSelected(index)
                                                    scrollRef.current.scrollIntoView({ behavior: 'smooth' })
                                                }}>See More</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )})
                            }
                        </div>
                        <div ref={scrollRef}>
                        {
                            selected !== undefined
                                ?
                                <div>
                                    <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                                        {`${galleries[selected].artist_name}'s work`}
                                    </h4>
                                    <Gallery images={galleries[selected].images} />
                                </div>
                                :
                                null
                        }
                        </div>
                    </div>
                </div>            
                
            </div>
        </section>
    );
};

GalleryPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func
};

const GalleryPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <GalleryPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                galleries={post.frontmatter.galleries}
            />
        </Layout>
    );
};

GalleryPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default GalleryPage;

export const aboutPageQuery = graphql`
    query GalleryPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                galleries {
                    artist_name
                    artist_about
                    images {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
