import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { default as PhotoGallery } from "react-photo-gallery";

const Gallery = ({ images }) => {
    console.log(images);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { index }) => {
      event.preventDefault()
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
    const galleryImages = images.map(({image}) => ({
        ...image.childImageSharp.fluid, 
        width: image.childImageSharp.fluid.aspectRatio,
        height: 1
    }))
    return (
        <div>
            <PhotoGallery photos={galleryImages} onClick={openLightbox} direction={"column"} />
            {/* {images.map((image, index) => <a href="#" onClick={(e) => openLightbox(e, {index})}><PreviewCompatibleImage imageInfo={image} /></a>)} */}
            <ModalGateway>
            {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel 
                        currentIndex={currentImage}
                        views={images.map(({image}) => image.childImageSharp.fluid)} 
                    />
                </Modal>
            ) : null}
            </ModalGateway>
        </div>
    )
}

Gallery.propTypes = {
  images: PropTypes.array,
}


export default Gallery
